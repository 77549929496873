export const DEPENDENCIES_TITLE = 'Dependencies';
export const FEATURES_TITLE = 'Features';
export const OBJECTIVES_TITLE = 'Objectives';
export const MILESTONES_TITLE = 'Milestones';
export const RISKS_TITLE = 'Risks';
export const OKR_TITLE = 'OKRs';
export const PIS_TITLE = 'Increments';
export const USERS_TITLE = 'Users';
export const INITIATIVES_TITLE = 'Initiatives';
export const PORTFOLIO_INITIATIVES_TITLE = 'Portfolio Initiatives';
export const VALUE_STREAMS_TITLE = 'Value Streams';
export const PROGRAMMES_TITLE = 'Programmes';
export const TEAMS_TITLE = 'Teams';
export const INTEGRATIONS_TITLE = 'Integrations';
export const BACKLOGS_TITLE = 'Projects / Products';
export const SUBTASKS_TITLE = 'Tasks';
export const HL_ESTIMATES_TITLE = 'High Level Estimates';
export const INITIATIVES_STATES = 'Initiative States';
export const PRODUCTTHEME_TITLE = 'Product Theme';
export const THINK_TANK = 'Think Tank';
