import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Card, CardContent, FormControl, FormGroup, FormLabel } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { useForm } from 'react-hook-form';

import {
  DELETE_VALUE_STREAM,
  INSERT_VALUE_STREAM,
  UPDATE_VALUE_STREAM,
} from '../../../containers/Settings/Streams/graphql';
import { resetDrawerDetails } from '../../../reactiveVariables';
import { BUTTONS } from '../../../utils/formConstants';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { removeIdField } from '../../../utils/helpers';
import { Field, FormContext, renderTextField, renderCheckbox } from '../../Common/FormFieldsHooks';

const ValueStreamDetails = (props, ref) => {
  const [insertValueStream] = useMutation(INSERT_VALUE_STREAM);
  const [updateValueStream] = useMutation(UPDATE_VALUE_STREAM);
  const [deleteValueStream] = useMutation(DELETE_VALUE_STREAM);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      ...props.element,
      isActive: props.element?.isActive !== false, // Set to true by default if not explicitly false
    },
  });

  useEffect(() => {
    if (props.element) {
      register('id');
      setValue('id', props.element.id);
    }
  }, [props.element, register, setValue]);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteValueStream({
      variables: { id: valueStream.id },
      update: deleteUpdate('value_stream', 'valueStream'),
    }).then(() => resetDrawerDetails());
  };

  const onSubmit = (values) => {
    const valueStreamToSave = removeIdField({
      ...values,
      isActive: values.isActive !== false, // Ensure it's a boolean
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    values.id
      ? updateValueStream({
          variables: { valueStream: valueStreamToSave, valueStreamId: values.id },
        }).then(() => resetDrawerDetails())
      : insertValueStream({
          variables: { valueStream: valueStreamToSave },
          update: insertUpdate('value_stream', 'valueStream'),
        }).then(() => resetDrawerDetails());
  };

  const valueStream = watch();

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" size={12} required />
            <Field
              name="description"
              multiline
              maxRows="100"
              renderField={renderTextField}
              label="Description"
              size={12}
            />
            <Field name="isActive" renderField={renderCheckbox} label="Active" size={15} />

            {props.additionalData?.length > 0 && (
              <FormControl sx={{ margin: '16px 0 16px 8px' }} component="fieldset" variant="standard">
                <FormLabel component="legend" style={{ marginBottom: '10px' }}>
                  Programmes
                </FormLabel>
                <FormGroup>
                  {props.additionalData.map((programme) => (
                    <FormLabel style={{ marginBottom: '10px', color: 'black', fontSize: '14px' }} key={programme.id}>
                      {programme.name}
                    </FormLabel>
                  ))}
                </FormGroup>
              </FormControl>
            )}
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete Value Stream: ${valueStream.name}`}
        text="Are you sure you want to delete this Value Stream ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(ValueStreamDetails);
