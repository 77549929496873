import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Slide } from '@mui/material';
import { some } from 'lodash';
import { DateTime } from 'luxon';
import { useNavigate, useLocation } from 'react-router-dom';
import { exportCSVData, getFieldValue } from 'utils/export';

import Table from '../../../components/Common/Table';
import { UPDATE_INITIATIVE_MANY } from '../../../containers/ValueStream/Initiatives/graphql';
import {
  openDrawer,
  selectedProductThemeVar,
  selectedValueStreamVar,
  selectedOwnerVar,
  selectedTeamVar,
} from '../../../reactiveVariables';
import { useProjectedFeatures } from '../../../utils/hooks';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { initiativeWithDates } from '../utils';
import { headCells } from './constants';
import FeatureList from './FeatureList';
import { GET_INITIATIVES, GET_ALL_INITIATIVES } from './graphql';
import { getRowsData } from './helper';

const Initiatives = (props, ref) => {
  const { searchQuery, type } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const { data: { initiatives = [] } = {}, refetch: refetchInitiatives } = useQuery(
    type === 'portfolioInitiative' ? GET_ALL_INITIATIVES : GET_INITIATIVES,
  );

  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);
  const [updateInitiativeMany] = useMutation(UPDATE_INITIATIVE_MANY);

  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedValueStream = useReactiveVar(selectedValueStreamVar);
  const selectedOwners = useReactiveVar(selectedOwnerVar);
  const selectedTeams = useReactiveVar(selectedTeamVar);

  const projectedFeatures = useProjectedFeatures();

  const filterByProductTheme = (initiative, selectedProductTheme) => {
    if (selectedProductTheme === 'x') {
      return initiative.productThemes.length === 0;
    }
    return !selectedProductTheme || some(initiative.productThemes, (theme) => theme.id === selectedProductTheme);
  };

  const filterByValueStream = (initiative, selectedValueStream) => {
    return (
      !selectedValueStream ||
      initiative.valueStream === selectedValueStream ||
      some(initiative.portfolioValueStreams, (portfolioValueStream) => portfolioValueStream.id === selectedValueStream)
    );
  };

  const filterByOwner = (initiative, selectedOwners) => {
    return selectedOwners.includes(initiative.owner);
  };

  const filterByTeams = (initiative, selectedTeams) => {
    return initiative.teams?.some((team) => selectedTeams.includes(team.id));
  };

  const initiativesWithDates = useMemo(() => {
    const filteredInitiatives = initiatives
      .filter((initiative) => {
        const themeFilter = filterByProductTheme(initiative, selectedProductTheme);

        const valueStreamFilter =
          type !== 'portfolioInitiative' ? filterByValueStream(initiative, selectedValueStream) : true;

        const portfolioFilter = type === 'portfolioInitiative' ? initiative.isPortfolioState === true : true;

        return themeFilter && valueStreamFilter && portfolioFilter;
      })
      .map((initiative) => initiativeWithDates(initiative, sprints, projectedFeatures));

    let finalInitiatives = filteredInitiatives;

    if (selectedOwners.length) {
      finalInitiatives = finalInitiatives.filter((initiative) => filterByOwner(initiative, selectedOwners));
    }

    if (selectedTeams.length) {
      finalInitiatives = finalInitiatives.filter((initiative) => filterByTeams(initiative, selectedTeams));
    }

    if (type === 'portfolioInitiative') {
      finalInitiatives = finalInitiatives.filter((initiative) => initiative.isPortfolioState === true);
    }

    return finalInitiatives;
  }, [
    selectedProductTheme,
    selectedValueStream,
    selectedOwners,
    selectedTeams,
    initiatives,
    sprints,
    projectedFeatures,
    type,
  ]);

  const isFilterActive = !!selectedOwners?.length || !!selectedTeams?.length || !!selectedProductTheme;

  useImperativeHandle(ref, () => ({
    export: () => exportInitiatives(),
  }));

  const exportInitiatives = useCallback(() => {
    const headCellsWithoutAction = headCells.filter((columnDef) => columnDef.id !== 'action');

    const data = initiativesWithDates.map((rowData) => {
      const formattedData = {
        rank: rowData.rank,
        name: rowData.name,
        featuresIn: `${rowData.features?.nodes.length} features`,
        initiative_state: rowData.initiative_state,
        estimatedCompletionDate: rowData.endDate?.toLocaleString(DateTime.DATE_SHORT),
        plannedRealisationDate: rowData.plannedRealisationDate,
        ragStatus: rowData.ragStatus,
        progress: `${rowData.features?.aggregate?.avg?.percentageDone || 0}%`,
      };

      return headCellsWithoutAction.map((columnDef) => {
        return getFieldValue(formattedData, { ...columnDef, field: columnDef.id });
      });
    });

    exportCSVData(headCellsWithoutAction, data, 'initiatives');
  }, [initiativesWithDates]);

  const getFeatures = (initiative, setLoading) => {
    return <FeatureList initiative={initiative} setLoading={setLoading} />;
  };

  const onRowClick = (event, row) => {
    const initiative = initiativesWithDates.find((initiative) => initiative?.id === row.id);
    openDrawer(initiative, 'initiative');
  };

  const onOpenDashboard = useCallback(
    (event, initiative) => {
      navigate('/valueStream/initiative/' + initiative.id, { state: { fromInitiativesList: true } });
    },
    [navigate],
  );

  const [tableRows, setTableRows] = useState(getRowsData(initiativesWithDates, onOpenDashboard, theme));

  const getTableRows = useCallback(() => {
    setTableRows(getRowsData(initiativesWithDates, onOpenDashboard, theme));
  }, [initiativesWithDates, onOpenDashboard, theme]);

  useEffect(() => {
    getTableRows();
  }, [initiativesWithDates, getTableRows]);

  const updateInitiativesOrder = () => {
    refetchInitiatives();
  };

  const updateInitiativeRank = (updatedRankOrder) => {
    const updates = updatedRankOrder.map((item, index) => ({
      where: { id: { _eq: item.id } },
      _set: { rank: index + 1, valueStream: selectedValueStream },
    }));

    updateInitiativeMany({
      variables: { updates },
    }).then(() => {
      updateInitiativesOrder(updatedRankOrder);
    });
  };

  return (
    <Slide in={true} direction={'right'} timeout={location.state?.from ? 400 : 0}>
      <div>
        <Table
          onRowClick={onRowClick}
          fields={type === 'portfolioInitiative' ? headCells.filter((columnDef) => columnDef.id !== 'rank') : headCells}
          searchQuery={searchQuery}
          rows={tableRows}
          expandFunc={getFeatures}
          updateInitiativesOrder={updateInitiativesOrder}
          enableDragAndDrop={type !== 'portfolioInitiative' && !isFilterActive}
          updateInitiativeRank={updateInitiativeRank}
          isFilterActive={isFilterActive}
        />
      </div>
    </Slide>
  );
};

export default forwardRef(Initiatives);
