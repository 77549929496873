import React from 'react';

import { SegmentedControl } from '@mantine/core';

import classes from './Switch.module.css';

export default function Switch({ data, value, onChange, sx }) {
  return (
    <SegmentedControl
      classNames={{
        root: classes.root,
        // label: classes.label,
        // control: classes.control
      }}
      style={sx}
      value={value}
      onChange={onChange}
      data={data}
    />
  );
}
