import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { useQuery } from '@apollo/client';

import { headCells } from './constants';
import { GET_VALUE_STREAMS } from './graphql';
import { getRowsData } from './helper';
import EntityTable from '../../../components/Common/EntityTable';
import { openDrawer } from '../../../reactiveVariables';
import { exportCSVData, getFieldValue } from '../../../utils/export';

const Streams = (props, ref) => {
  const { searchQuery } = props;
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);

  useImperativeHandle(ref, () => ({
    export: () => exportValueStreams(),
  }));

  const exportValueStreams = useCallback(() => {
    const data = valueStreams.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Value Streams');
  }, [valueStreams]);

  const onRowClick = (event, row) => {
    const valueStream = valueStreams.find((stream) => stream?.id === row.id);
    openDrawer(valueStream, 'valueStream', valueStream.programmes);
  };

  return (
    <EntityTable
      headCells={headCells}
      rows={getRowsData(valueStreams)}
      searchQuery={searchQueryLowerCase}
      expand={false}
      handleRowClick={onRowClick}
    />
  );
};

export default forwardRef(Streams);
