import React, { useRef, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/system';
import config from 'Config';
import Dependencies from 'containers/Delivery/components/Dependencies';
import Objectives from 'containers/Delivery/components/Objectives';
import ProgramRisks from 'containers/Delivery/components/Risks';
import ThinkTank from 'containers/Organisation/ThinkTank';
import Features from 'containers/Product/Features';
import Milestones from 'containers/ValueStream/Milestones';
import ProductTheme from 'containers/ValueStream/ProductTheme';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { getTitle, getTooltipMessage } from './helper';
import TableToolBar from './TableToolBar';
import ProgramIncrements from '../../../containers/Settings/ProgramIncrement';
import Programmes from '../../../containers/Settings/Programmes';
import Streams from '../../../containers/Settings/Streams';
import Teams from '../../../containers/Settings/Teams';
import Initiatives from '../../../containers/ValueStream/Initiatives';
import { openDrawer, selectedProgrammeVar } from '../../../reactiveVariables';
import Backlogs from '../../Settings/Backlogs';
import Integrations from '../../Settings/Integration';
import Users from '../../Settings/Users';

const Container = styled('div')(({ theme, ...props }) => ({
  margin: 0,
  padding: 0,
  width: '100%',
  minWidth: 'fit-content',
  height: props.fullHeight ? '100%' : undefined,
}));

const envConfig = config[window.location.hostname];

const BigAgileTable = (props) => {
  const { type, title, Component } = props;
  const [query, setQuery] = useState('');
  const [additionalData, setAdditionalData] = useState({});
  const hideToolBar = type === 'risk' || type === 'hlEstimate';
  const selectedProgramme = useReactiveVar(selectedProgrammeVar);

  const navigate = useNavigate();

  const disableAddButton =
    (type === 'user' && envConfig.identityProvider) ||
    (['feature', 'objective', 'dependency', 'increment', 'risk', 'productTheme'].includes(type) && !selectedProgramme);

  const child = useRef(null);

  function onHandleCreate() {
    if (type === 'thinkTank') {
      return navigate(`/submit-idea/new`);
    }

    return openDrawer(null, type, additionalData);
  }

  function renderComponent() {
    switch (type) {
      case 'feature':
        return <Features ref={child} searchQuery={query} filterByPi={props.filterByPi} />;
      case 'objective':
        return <Objectives ref={child} searchQuery={query} />;
      case 'dependency':
        return <Dependencies ref={child} searchQuery={query} />;
      case 'milestone':
        return <Milestones ref={child} searchQuery={query} />;
      case 'risk':
        return <ProgramRisks />;
      case 'increment':
        return <ProgramIncrements ref={child} searchQuery={query} />;
      case 'user':
        return <Users ref={child} searchQuery={query} {...props} />;
      case 'initiative':
      case 'portfolioInitiative':
        return <Initiatives ref={child} searchQuery={query} {...props} type={type} />;
      case 'valueStream':
        return <Streams ref={child} searchQuery={query} {...props} />;
      case 'programme':
        return <Programmes ref={child} searchQuery={query} {...props} />;
      case 'team':
        return <Teams ref={child} searchQuery={query} {...props} />;
      case 'integration':
        return <Integrations ref={child} searchQuery={query} {...props} />;
      case 'backlog':
        return <Backlogs ref={child} searchQuery={query} {...props} />;
      case 'productTheme':
        return <ProductTheme ref={child} searchQuery={query} {...props} />;
      case 'thinkTank':
        return <ThinkTank ref={child} searchQuery={query} {...props} />;
    }
  }

  function onHandleDownload() {
    child.current.export();
  }

  function onHandleSearch(ev) {
    setQuery(ev.target.value);
  }

  const additionalDataCallback = (data) => {
    setAdditionalData(data);
  };

  return (
    <Container fullHeight={type === 'risk'}>
      {!hideToolBar && (
        <TableToolBar
          tooltipMessage={getTooltipMessage(type, disableAddButton)}
          disableAddButton={disableAddButton}
          title={title || getTitle(type)}
          onHandleAdd={onHandleCreate}
          handleDownloadClick={onHandleDownload}
          handleSearch={onHandleSearch}
          hideToolBar={type === 'initiativeState'}
        />
      )}
      {Component ? (
        <Component ref={child} searchQuery={query} additionalDataCallback={additionalDataCallback} {...props} />
      ) : (
        renderComponent()
      )}
    </Container>
  );
};

BigAgileTable.prototype = {
  type: PropTypes.string,
};

export default BigAgileTable;
