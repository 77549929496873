import React from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { ActionIcon, Center, Grid, SimpleGrid, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import Initiative from './Initiative';
import classes from './InitiativeDashboard.module.css';
import { getColumTitle, moveDatesLeft, moveDatesRight } from './utils';
import { Dot } from '../../../components/Common/Elements';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { GET_PROGRAMMES } from '../../Settings/GraphQL/programmes';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';

const TimeLineDashboard = ({
  initiatives,
  columDates,
  setColumnDates,
  projectedFeatures,
  loading,
  dateType,
  expandedByDefault = false,
  view = 'initiatives_timeline',
}) => {
  const theme = useTheme();
  const { ref, width, height } = useElementSize();
  const atRiskInitiatives = initiatives.filter((initiative) => !initiative.isDone && initiative.ragStatus !== 'green');
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);
  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);

  let featuresAtRisk = '';

  if (initiatives[0]?.features?.nodes?.length) {
    featuresAtRisk = initiatives[0]?.features?.nodes.filter(
      (feature) => feature.status !== 'Done' && feature.ragStatus !== 'green',
    );
  }

  return (
    <>
      <Grid
        gutter={0}
        align={'center'}
        style={{
          margin: 0,
          position: 'sticky',
          top: '-28px',
          zIndex: 100,
          backgroundColor: theme.palette.color.background,
          borderBottom: `1px solid ${theme.palette.color.tableColumn}`,
          borderLeft: 'none',
          borderRight: 'none',
        }}>
        <Grid.Col span={4} h="70px" className={classes.headerCell}>
          <div>
            <Text size="sm" fw={700}>
              {view === 'features_timeline' ? 'Features' : 'Initiatives'}
            </Text>
            <Text size="xs" fw={400} c={theme.palette.text.secondary}>
              {view === 'features_timeline' ? initiatives[0]?.features?.nodes?.length : initiatives.length} Total{' '}
              <Dot
                sx={{
                  marginLeft: '4px',
                  marginRight: '3px',
                  height: '6px',
                  width: '6px',
                  backgroundColor: theme.palette.text.secondary,
                }}
              />{' '}
              {view === 'features_timeline'
                ? featuresAtRisk.length + '/' + initiatives[0]?.features?.nodes?.length
                : atRiskInitiatives.length + '/' + initiatives.length}{' '}
              At Risk
            </Text>
          </div>
        </Grid.Col>
        <Grid.Col align={'center'} ref={ref} span={8} p={0}>
          <SimpleGrid spacing={0} h="70px" m={0} cols={4}>
            {columDates.map((dates, index) => (
              <div
                className={classes.headerCell}
                data-column={true}
                data-last={index === columDates.length - 1}
                key={index}>
                {index === 0 && (
                  <ActionIcon
                    variant={theme.palette.mode === 'dark' ? 'filled' : 'outline'}
                    bg={theme.palette.mode === 'dark' ? theme.palette.color.buttonIcon : theme.palette.color.paper}
                    ml={10}
                    onClick={() => setColumnDates(moveDatesLeft(dateType, columDates))}>
                    <KeyboardArrowLeft />
                  </ActionIcon>
                )}
                <Center h="100%" style={{ flexDirection: 'column', flexGrow: 1 }}>
                  <Text size="sm" align={'center'} fw={700}>
                    {getColumTitle(dateType, dates)}
                  </Text>
                  <Text size="xs" align={'center'} fw={400} c={'dimmed'}>
                    {dates.start.toFormat('dd/LL')} - {dates.end.toFormat('dd/LL')}
                  </Text>
                </Center>
                {index === columDates.length - 1 && (
                  <ActionIcon
                    variant={theme.palette.mode === 'dark' ? 'filled' : 'outline'}
                    bg={theme.palette.mode === 'dark' ? theme.palette.color.buttonIcon : theme.palette.color.paper}
                    mr={10}
                    onClick={() => setColumnDates(moveDatesRight(dateType, columDates))}>
                    <KeyboardArrowRight />
                  </ActionIcon>
                )}
              </div>
            ))}
          </SimpleGrid>
        </Grid.Col>
      </Grid>
      {!!width &&
        !loading &&
        initiatives.map((initiative, index) => (
          <Initiative
            initiative={initiative}
            key={initiative.id}
            width={width}
            height={height}
            lastChild={index === initiatives.length - 1}
            firstChild={index === 0}
            columnDates={columDates}
            projectedFeatures={projectedFeatures}
            expandedByDefault={expandedByDefault}
            features={features}
            sprints={sprints}
            programmes={programmes}
          />
        ))}
    </>
  );
};

export default TimeLineDashboard;
