import React from 'react';

import { useTheme } from '@emotion/react';

import Select from '../Select';

const TeamSelector = ({ options, value, handleChange }) => {
  const values = options.map((team) => ({ value: team.id, label: team.name }));
  const theme = useTheme();

  return (
    <Select
      title="Team"
      placeholder="Select a team..."
      value={value || []}
      onChange={(event, values) => handleChange(values)}
      rootStyle={{ marginLeft: '-6px', marginRight: '-22px' }}
      toggleStyle={{
        borderRadius: '5px',
        backgroundColor: theme.palette.mode === 'dark' ? '#24292f' : theme.palette.color.paper,
        borderColor: theme.palette.mode === 'dark' ? '#424a53' : '#d0d7de',
      }}
      defaultValue={[0]}
      multiple={true}
      options={values}
    />
  );
};

export default TeamSelector;
