import React from 'react';

import { Route } from 'react-router-dom';

import InitiativesRoadmap from './InitiativesRoadmap';
import OKRs from './OKRs/Summary';
import Index from './OKRs/View';
import OperationalPortfolio from './Portfolio/index';
import BigAgileTable from '../../components/Common/BigAgileTable';

const getOrganisationRoutes = (user) => (
  <>
    <Route user={user} path="/organisation/dashboard" element={<InitiativesRoadmap />} />
    <Route user={user} path="/organisation/okrs" element={<OKRs />} />
    <Route user={user} path="/organisation/okrs/:valueStreamId" element={<Index />} />
    <Route user={user} path="/organisation/portfolio" element={<OperationalPortfolio />} />
    <Route user={user} path="/organisation/initiatives" element={<BigAgileTable type="portfolioInitiative" />} />
    <Route user={user} path="/organisation/thinkTank" element={<BigAgileTable type="thinkTank" />} />
  </>
);

export default getOrganisationRoutes;
