import React, {memo, useContext} from 'react';

import {Grid, NumberInput, Select, Textarea, TextInput, useMantineTheme} from '@mantine/core';
import {DateInput} from '@mantine/dates';
import {DateTime} from 'luxon';
import {Controller} from 'react-hook-form';

import Calendar from '../../../assets/images/Calendar';
import {SelectCreatable} from '../CreatableSelect';
import SearchableGroupSelect from './SearchableGroupSelect';

export const FormContext = React.createContext({});

export const Field = memo(
  ({ name, size, defaultValue = '', renderField, sx, noSize, title, required = false, ...props }) => {
    const { control } = useContext(FormContext);
    const rules = required ? { required: `${props.label || title} is required`, ...props.rules } : props.rules;

    return (
      <Grid.Col style={{ marginTop: 0, ...sx }} key={name} {...{ span: noSize ? undefined : size || 12 }}>
        <Controller
          {...props}
          render={(renderProps) =>
            renderField({
              error: !!renderProps.fieldState.error,
              helperText: renderProps.fieldState.error?.message,
              ...renderProps.field,
              ...props,
              required,
            })
          }
          name={name}
          defaultValue={defaultValue}
          control={control}
          rules={rules}
          required={required}
        />
      </Grid.Col>
    );
  },
);

const InputText = ({ label, required, placeholder, ...other }) => {
  const theme = useMantineTheme();

  return (
    <TextInput
      withAsterisk={required}
      radius={8}
      {...other}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D' },
        label: { color: theme.other.colours.secondary },
      }}
      label={label}
      placeholder={placeholder}
    />
  );
};

const InputTextarea = ({ label, required, placeholder, ...other }) => {
  const theme = useMantineTheme();

  return (
    <Textarea
      withAsterisk={required}
      radius={8}
      rows={6}
      {...other}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D' },
        label: { color: theme.other.colours.secondary },
      }}
      label={label}
      placeholder={placeholder}
    />
  );
};

const Combobox = ({ width, required, maxWidth, disabled, ...props }) => {
  const theme = useMantineTheme();

  return (
    <Select
      withAsterisk={required}
      disabled={disabled}
      variant={disabled ? 'filled' : 'default'}
      radius={8}
      {...props}
      styles={{
        wrapper: { boxShadow: disabled ? '' : '0px 2px 2px 0px #0000000D', width, maxWidth },
        label: { color: theme.other.colours.secondary },
      }}
    />
  );
};

const CreatableCombobox = ({ width, required, maxWidth, disabled, ...props }) => {
  const theme = useMantineTheme();

  return (
    <SelectCreatable
      withAsterisk={required}
      disabled={disabled}
      variant={disabled ? 'filled' : 'default'}
      radius={8}
      {...props}
      styles={{
        wrapper: { boxShadow: disabled ? '' : '0px 2px 2px 0px #0000000D', width, maxWidth },
        label: { color: theme.other.colours.secondary },
      }}
    />
  );
};

const DateField = ({ width, required, maxWidth, ...props }) => {
  const theme = useMantineTheme();

  return (
    <DateInput
      leftSection={<Calendar />}
      withAsterisk={required}
      radius={8}
      {...props}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D', width, maxWidth },
        label: { color: theme.other.colours.secondary },
      }}
    />
  );
};

const InputNumber = ({ label, placeholder, required, ...other }) => {
  const theme = useMantineTheme();

  return (
    <NumberInput
      withAsterisk={required}
      radius={8}
      {...other}
      styles={{
        wrapper: { boxShadow: '0px 2px 2px 0px #0000000D' },
        label: { color: theme.other.colours.secondary },
      }}
      label={label}
      placeholder={placeholder}
    />
  );
};

export const renderInput = ({
  input,
  label,
  children,
  renderValue,
  defaultValue,
  handleChange,
  onChange,
  margin = 'dense',
  ...custom
}) => (
  <InputText
    label={label}
    placeholder={label}
    margin={margin}
    onChange={handleChange || onChange}
    fullWidth
    {...input}
    {...custom}
    SelectProps={{
      renderValue: renderValue,
      defaultValue: defaultValue,
    }}>
    {children}
  </InputText>
);

export const renderInputTextArea = ({
  input,
  label,
  children,
  renderValue,
  defaultValue,
  handleChange,
  onChange,
  margin = 'dense',
  ...custom
}) => (
  <InputTextarea
    label={label}
    placeholder={label}
    margin={margin}
    onChange={handleChange || onChange}
    fullWidth
    {...input}
    {...custom}
    SelectProps={{
      renderValue: renderValue,
      defaultValue: defaultValue,
    }}>
    {children}
  </InputTextarea>
);

export const renderCombobox = ({
  input,
  label,
  children,
  renderValue,
  defaultValue,
  handleChange,
  onChange,
  helperText,
  error,
  ...custom
}) => (
  <Combobox
    label={label}
    placeholder={label}
    comboboxProps={{ zIndex: 5000 }}
    error={helperText}
    onOptionSubmit={handleChange || onChange}
    {...custom}
  />
);

export const renderCreatableCombobox = ({
  input,
  label,
  children,
  renderValue,
  defaultValue,
  handleChange,
  onChange,
  ...custom
}) => (
  <CreatableCombobox
    label={label}
    placeholder={label}
    comboboxProps={{ zIndex: 5000 }}
    onOptionSubmit={handleChange || onChange}
    {...custom}
  />
);

export const renderDate = ({
  input,
  label,
  renderValue,
  defaultValue,
  handleChange,
  value,
  clearable = true,
  onChange,
  ...custom
}) => (
  <DateField
    label={label}
    placeholder={label}
    value={value && new Date(value)}
    onChange={(value) => onChange(value ? DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') : null)}
    popoverProps={{ zIndex: 5000 }}
    clearable={clearable}
    {...custom}
  />
);

export const renderGroupedSelect = ({
                             input,
                             label,
                             renderValue,
                             defaultValue,
                             handleChange,
                             value,
                             clearable = true,
                             onChange,
                             options,
                             ...custom
                           }) => (
  <SearchableGroupSelect
    label={label}
    placeholder={label}
    items={options}
    value={value || []}
    onChange={(value) => onChange(value)}
    popoverProps={{ zIndex: 5000 }}
    clearable={clearable}
    {...custom}
  />
);

export const renderNumberInput = ({ input, renderValue, defaultValue, handleChange, onChange, ...custom }) => (
  <InputNumber hideControls onChange={handleChange || onChange} fullWidth {...input} {...custom} />
);
