import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, CardContent, MenuItem, FormControl, FormGroup, FormLabel } from '@mui/material';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import { useForm } from 'react-hook-form';

import { DELETE_PROGRAMME, INSERT_PROGRAMME, UPDATE_PROGRAMME } from '../../../containers/Settings/GraphQL/programmes';
import { GET_VALUE_STREAMS } from '../../../containers/Settings/Streams/graphql';
import { resetDrawerDetails } from '../../../reactiveVariables';
import { BUTTONS } from '../../../utils/formConstants';
import { deleteUpdate, insertUpdate } from '../../../utils/graphQLUtils';
import { removeIdField } from '../../../utils/helpers';
import { Field, FormContext, renderSelectField, renderTextField } from '../../Common/FormFieldsHooks';

const ProgrammeDetails = (props, ref) => {
  const [insertProgramme] = useMutation(INSERT_PROGRAMME);
  const [updateProgramme] = useMutation(UPDATE_PROGRAMME);
  const [deleteProgramme] = useMutation(DELETE_PROGRAMME);

  const { additionalData } = props;

  const { data: { valueStreams = [] } = {} } = useQuery(GET_VALUE_STREAMS);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    register,
    formState: { isDirty, errors },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      ...props.element,
      valueStream: props.element?.valueStream?.id,
    },
  });

  useEffect(() => {
    if (props.element) {
      register('id');
      setValue('id', props.element.id);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      handleSubmit(onSubmit)();
    },
    handleDelete: () => {
      if (props.element && props.element.id) setOpenConfirmation(true);
    },
    handleClose: () => {
      props.canEdit && isDirty ? setOpenWarning(!openWarning) : resetDrawerDetails();
    },
  }));

  const handleDeleteConfirm = () => {
    deleteProgramme({
      variables: { id: programme.id },
      update: deleteUpdate('programme'),
    }).then(() => resetDrawerDetails());
  };

  const onSubmit = (values) => {
    const programmeToSave = removeIdField(values);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    values.id
      ? updateProgramme({
          variables: { programme: programmeToSave, programmeId: values.id },
        }).then(() => resetDrawerDetails())
      : insertProgramme({
          variables: { programme: programmeToSave },
          update: insertUpdate('programme'),
        }).then(() => resetDrawerDetails());
  };

  const programme = watch();

  return (
    <Card elevation={0}>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContext.Provider value={{ control, errors }}>
            <Field name="name" renderField={renderTextField} label="Name" size={12} required />
            <Field
              name="description"
              multiline
              maxRows="100"
              renderField={renderTextField}
              label="Description"
              size={12}
            />
            <Field required name="valueStream" renderField={renderSelectField} label="Value Stream" size={12}>
              {valueStreams.map((element) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </Field>

            {additionalData?.length > 0 && (
              <FormControl sx={{ margin: '16px 0 16px 8px' }} component="fieldset" variant="standard">
                <FormLabel component="legend" style={{ marginBottom: '10px' }}>
                  Teams
                </FormLabel>
                <FormGroup>
                  {additionalData.map((team) => (
                    <FormLabel style={{ marginBottom: '10px', color: 'black', fontSize: '14px' }} key={team.id}>
                      {team.name}
                    </FormLabel>
                  ))}
                </FormGroup>
              </FormControl>
            )}
          </FormContext.Provider>
        </form>
      </CardContent>
      <ConfirmationDialog
        open={openConfirmation}
        title={`Delete programme: ${programme.name}`}
        text="Are you sure you want to delete this Programme ?"
        handleOk={handleDeleteConfirm}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationDialog
        open={openWarning}
        title="Unsaved changes"
        text="You have unsaved changes. What would you like to do?">
        <Button
          onClick={() => {
            setOpenWarning(!openWarning);
            handleSubmit(onSubmit)();
          }}
          color="primary">
          {BUTTONS.SAVE}
        </Button>
        <Button onClick={() => resetDrawerDetails()} color="primary">
          {BUTTONS.DISCARD}
        </Button>
        <Button onClick={() => setOpenWarning(!openWarning)} color="primary">
          {BUTTONS.CANCEL}
        </Button>
      </ConfirmationDialog>
    </Card>
  );
};

export default forwardRef(ProgrammeDetails);
