function createData(item) {
  const { id, name, inUse, stateType, rank } = item;

  return {
    id,
    name,
    inUse: inUse ? 'Yes' : 'No',
    stateType,
    rank,
  };
}

export function getRowsData(data) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item));
  }
  return rows;
}
