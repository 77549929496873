import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { ActionIcon, Grid, Group, Paper, Text, ThemeIcon } from '@mantine/core';
import ReturnIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Slide } from '@mui/material';
import { DateTime } from 'luxon';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';

import Dates from './Dates';
import DistributionTable from './DistributionTable';
import InitiativeBurnup from './InitiativeBurnup';
import TeamBreakdown from './TeamBreakdown';
import { buildPieData } from './utils';
import { DashboardCard } from '../../../components/Common/Elements';
import Switch from '../../../components/Common/Switch';
import { useProjectedFeatures } from '../../../utils/hooks';
import ProgressionChart from '../../Delivery/components/Dashboard/Overview/ProgressionChart';
import { useDeliveryMetrics } from '../../Delivery/components/Dashboard/utils';
import { GET_FEATURES } from '../../Product/Features/graphql';
import { GET_PROGRAMMES } from '../../Settings/GraphQL/programmes';
import { GET_TEAMS } from '../../Settings/GraphQL/teams';
import { GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { GET_INITIATIVES } from '../Initiatives/graphql';
import { MilestonesHealth, UpcomingMilestones } from '../InitiativesDashboard/MetricPaper';
import TimeLineDashboard from '../InitiativesDashboard/TimeLineDashboard';
import { getColumnDates } from '../InitiativesDashboard/utils';
import { GET_MILESTONES_WITH_FEATURES } from '../Milestones/graphql';
import { initiativeWithDates } from '../utils';

export const MetricPaper = ({ icon, title, children, xs = 3, sx }) => {
  const theme = useTheme();

  return (
    <Grid.Col span={xs}>
      <Paper
        style={{
          height: '100%',
          width: '100%',
          border: `1px solid ${theme.palette.color.tileBorder}`,
          borderRadius: '8px',
          backgroundColor: theme.palette.color.panel,
          ...sx,
          boxShadow: `0px 1px 0px 0px ${theme.palette.color.shadow}`,
        }}>
        <Grid.Col span={12}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text size="md" fw={700}>
              {title}
            </Text>
            {icon && (
              <ThemeIcon radius="xl" size={32} variant="light" color={'bigagilePink.0'}>
                {icon}
              </ThemeIcon>
            )}
          </div>
        </Grid.Col>
        <Grid p={8} align={'center'}>
          {children}
        </Grid>
      </Paper>
    </Grid.Col>
  );
};

const InitiativeView = () => {
  const { initiativeId } = useParams();
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES);
  const { loading: initiativesLoading, data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: milestonesData } = useQuery(GET_MILESTONES_WITH_FEATURES);
  const { loading: sprintsLoading, data: { sprints = [] } = {} } = useQuery(GET_SPRINTS);
  const { loading: programmesLoading, data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);
  const { loading: teamsLoading, data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  const loading = initiativesLoading || sprintsLoading;
  const projectedFeatures = useProjectedFeatures();
  const navigate = useNavigate();
  const location = useLocation();

  const [typeBurnup, setTypeBurnup] = useState('storyPoints');
  const [cadence, setCadence] = useState('weekly');
  const [dateType, setDateType] = useState('quarter');
  const [columDates, setColumnDates] = useState(getColumnDates(dateType));

  const initiative = initiatives.find((initiative) => initiative.id === Number(initiativeId));

  const initiativeMilestones = (milestonesData?.milestones || []).filter((milestone) => {
    return initiative?.milestones?.some((initMilestone) => {
      return milestone.id === initMilestone.id;
    });
  });

  const upcomingMilestones = initiativeMilestones
    .filter((milestone) => {
      const milestoneDate = DateTime.fromISO(milestone.date);
      const isUpcoming = milestoneDate > DateTime.now();
      return isUpcoming;
    })
    .slice(0, 3);

  const filteredFeatures = useMemo(
    () => features.filter((feature) => feature.initiativeId === Number(initiativeId)),
    [features, initiativeId],
  );
  const { doneFeatures, toDoFeatures, inProgressFeatures } = useDeliveryMetrics(filteredFeatures);

  const initiativeWithDate = useMemo(
    () => (initiative ? initiativeWithDates(initiative, sprints, projectedFeatures) : {}),
    [initiative, sprints, projectedFeatures],
  );

  const onDateTypeChange = (value) => {
    setColumnDates(getColumnDates(value));
    setDateType(value);
  };

  const handleGoBack = () => {
    if (location.state?.from === '/valueStream/kanban') {
      navigate('/valueStream/kanban', {
        state: {
          activeTab: location.state.tab,
          from: location.pathname,
        },
      });
    } else {
      navigate(location.state?.from || '/valueStream/initiatives', {
        state: { from: location.pathname },
      });
    }
  };

  return (
    <Slide in={!loading} direction={'left'}>
      <div style={{ height: '100%', width: '100%', padding: '0 50px' }}>
        <Group align={'center'} mb={16}>
          <ActionIcon ml={4} onClick={handleGoBack}>
            <ReturnIcon />
          </ActionIcon>
          <Text size="lg" fw={700}>
            Initiative View: {initiativeWithDate.name}
          </Text>
        </Group>
        <Grid>
          <MetricPaper sx={{ padding: '8px 12px' }} title="Feature Progression">
            <div style={{ height: '200px', width: '100%' }}>
              <ProgressionChart
                isDone={!toDoFeatures && !inProgressFeatures}
                renderData={buildPieData(toDoFeatures, doneFeatures, inProgressFeatures)}
                title="Features"
                showLegend={false}
              />
            </div>
          </MetricPaper>
          <MetricPaper sx={{ padding: '8px 12px' }} title="Timeline" xs={3}>
            <div style={{ height: '200px', width: '100%' }}>
              {!loading && initiative && <Dates initiative={initiativeWithDate} />}
            </div>
          </MetricPaper>
          <UpcomingMilestones xs={3} title="Upcoming Milestones" milestones={upcomingMilestones} />
          <MilestonesHealth xs={3} title="Milestones Health" milestones={initiativeMilestones} />
          <Grid.Col span={12}>
            <Grid>
              <MetricPaper xs={6} title="Programme Breakdown">
                {!programmesLoading && <DistributionTable initiative={initiative} programmes={programmes} />}
              </MetricPaper>
              <MetricPaper xs={6} title="Team Breakdown">
                {!teamsLoading && <TeamBreakdown initiative={initiative} teams={teams} />}
              </MetricPaper>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12}>
            <DashboardCard
              title="Initiative Burnup"
              cardStyle={{ maxHeight: null, height: 'calc(70vh - 36px)' }}
              xs={12}
              action={
                <>
                  <Switch
                    sx={{ marginBottom: 0, marginRight: 10 }}
                    value={cadence}
                    onChange={(value) => setCadence(value)}
                    data={[
                      { label: 'Weekly', value: 'weekly' },
                      { label: 'Fortnightly', value: 'fortnightly' },
                      { label: 'Monthly', value: 'monthly' },
                    ]}
                  />
                  <Switch
                    sx={{ marginBottom: 0 }}
                    value={typeBurnup}
                    onChange={(value) => setTypeBurnup(value)}
                    data={[
                      { label: 'Story Points', value: 'storyPoints' },
                      { label: 'Story Count', value: 'storyCount' },
                    ]}
                  />
                </>
              }>
              {initiativeWithDate.features && !loading && (
                <InitiativeBurnup
                  type={typeBurnup}
                  cadence={cadence}
                  initiative={initiativeWithDate}
                  sprints={sprints}
                />
              )}
            </DashboardCard>
          </Grid.Col>
          <Group position="apart" align="center" mb={0} mt={15} style={{ width: '100%' }}>
            <Text size="lg" fw={700}>
              Initiative Roadmap
            </Text>

            <Switch
              value={dateType}
              onChange={onDateTypeChange}
              data={[
                { label: 'Month', value: 'month' },
                { label: 'Quarter', value: 'quarter' },
                { label: 'Year', value: 'year' },
              ]}
              sx={{ marginBottom: 0, marginLeft: 'auto' }}
            />
          </Group>
          <div style={{ width: '100%', height: '400px', marginTop: '30px' }}>
            <TimeLineDashboard
              initiatives={[initiativeWithDate]}
              projectedFeatures={projectedFeatures}
              columDates={columDates}
              setColumnDates={setColumnDates}
              dateType={dateType}
              expandedByDefault={true}
              view="features_timeline"
            />
          </div>
        </Grid>
      </div>
    </Slide>
  );
};

export default InitiativeView;
