// React and third-party libraries
import React, { useEffect, useRef, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

// Material-UI components and hooks
import DeleteIcon from '@mui/icons-material/Delete';
import { Backdrop, SvgIcon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

// Local component imports
import DependencyDetails from 'components/Dependencies/DependencyDetails';
import FeatureDetails from 'components/Features/FeatureDetails';
import ExportDialog from 'components/Features/FeatureDetails/ExportDialog';
import ImportDialog from 'components/Features/FeatureDetails/ImportDialog';
import ObjectiveDetails from 'components/Objectives/ObjectiveDetails';
import ProductThemeDetails from 'components/ProductTheme/ProductThemeDetails';
import ProgramIncrementDetails from 'components/ProgramIncrements/ProgramIncrementDetails';
import ConnectorDialog from 'components/Settings/ConnectorDialog';
import UserDetails from 'components/Settings/Users/UserDetails';
import { upperFirst } from 'lodash';

// Relative imports
import { styles } from './styles';
import { envConfig } from '../../app';
import Close from '../../assets/images/Close';

// Asset imports
import Import from '../../assets/images/Import';
import Jira from '../../assets/images/Jira';

// Configuration and utility imports
import { drawerDetailsVar, drawerLoadingVar, resetDrawerDetails } from '../../reactiveVariables';
import RoundButton from '../Common/Button/RoundButton';
import { ErrorBoundary } from '../Common/ErrorBoundary';
import InitiativeDetails from '../Initiatives/InitiativeDetails';
import MilestoneDetails from '../Milestones/MilestoneDetails';
import ThinkTankDetails from '../Organisation/ThinkTankDetails';
import ProgrammeDetails from '../Programmes/ProgrammeDetails';
import RiskDetails from '../Risks';
import BacklogDetails from '../Settings/Backlogs/BacklogDetails';
import InitiativeStateDetails from '../Settings/Configuration/InitiativeStatesDetails';
import SubtaskDetails from '../Settings/Configuration/SubtaskDetails';
import TeamDetails from '../Teams/TeamDetails';
import ValueStreamDetails from '../ValueStream/ValueStreamDetails';

const contentComponent = {
  increment: ProgramIncrementDetails,
  feature: FeatureDetails,
  objective: ObjectiveDetails,
  milestone: MilestoneDetails,
  dependency: DependencyDetails,
  user: UserDetails,
  risk: RiskDetails,
  integration: ConnectorDialog,
  initiative: InitiativeDetails,
  portfolioInitiative: InitiativeDetails,
  programme: ProgrammeDetails,
  valueStream: ValueStreamDetails,
  team: TeamDetails,
  backlog: BacklogDetails,
  subtask: SubtaskDetails,
  productTheme: ProductThemeDetails,
  initiativeState: InitiativeStateDetails,
  thinkTank: ThinkTankDetails,
};

const permissionName = {
  increment: 'admin',
  feature: 'product',
  productTheme: 'product',
  objective: 'delivery',
  dependency: 'delivery',
  milestone: 'product',
  user: 'admin',
  idea: 'product',
  risk: 'delivery',
  connector: 'full-admin',
  initiative: 'product',
  okr: 'product',
  valueStream: 'value-stream',
  programme: 'value-stream',
  team: 'admin',
  backlog: 'admin',
  integration: 'full-admin',
  subtask: 'admin',
  initiativeState: 'admin',
  thinkTank: 'admin',
};

const useStyles = makeStyles(styles);

export const DrawerBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

export default function DrawerView(props) {
  const child = useRef(null);
  const details = useReactiveVar(drawerDetailsVar);
  const loading = useReactiveVar(drawerLoadingVar);

  const classes = useStyles();
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const theme = useTheme();

  const { roles = [] } = props.user;
  const permName = permissionName[details.category] || '';

  let canDelete =
    roles.includes(`delete-${permName}`) ||
    (permName.includes('admin') &&
      roles.some((role) => role.includes(permName)) &&
      !window.location.pathname.includes('/organisation/thinkTank'));

  const canDeleteOwn =
    roles.includes(`delete-own-${permName}`) ||
    (permName.includes('admin') && roles.some((role) => role.includes(permName)));
  const canEdit =
    roles.includes(`cru-${permName}`) || (permName.includes('admin') && roles.some((role) => role.includes(permName)));
  const canExport = roles.includes('cru-product');

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => document.removeEventListener('keydown', escFunction, false);
  });

  const escFunction = (event) => {
    if (event.keyCode === 27 && details.open) {
      openImport ? setOpenImport(false) : handleClose();
    }
  };

  const handleSave = () => {
    if (!child.current) resetDrawerDetails();
    child.current.handleSave();
  };

  const handleDelete = () => {
    if (!child.current) resetDrawerDetails();
    child.current.handleDelete();
  };

  const handleClose = () => {
    if (!child.current) resetDrawerDetails();
    child.current.handleClose();
  };

  const getHeaderTitle = () => {
    if (details.category === 'initiativeState') return 'Initiative State';
    if (details.category === 'portfolioInitiative') return 'Portfolio initiative';
    if (details?.element?.__typename === 'ideas') return `${details.element.id} - ${details?.element?.idea?.name}`;
    if (details?.element?.email) return details.element.name;
    if (details?.element?.id) return `${details.element.id} - ${details.element.name}`;

    return `New ${upperFirst(details.category)}`;
  };

  const importFromJira = () => setOpenImport(true);
  const cancelImport = () => setOpenImport(false);

  if (details.additionalData?.canDelete === false) {
    canDelete = false;
  }

  const renderHeader = () => {
    return (
      <AppBar
        position="static"
        color="secondary"
        elevation={theme.palette.mode === 'light' ? 0 : 2}
        className={classes.appBar}>
        <Toolbar>
          <Typography variant="subtitle1" noWrap className={classes.flex}>
            {getHeaderTitle()}
          </Typography>
          <div style={{ textAlign: 'right', flex: 1 }}>
            {envConfig.tooling === 'jira' && details.category === 'feature' && !details.element && (
              <Tooltip id="importTooltip" title="Import from JIRA">
                <IconButton aria-label="sync" onClick={importFromJira} className={classes.button} size="large">
                  <SvgIcon component={Import} />
                </IconButton>
              </Tooltip>
            )}
            {canExport && details.category === 'feature' && !details.element?.epicId && details.element?.team && (
              <Tooltip id="exportTooltip" title="Export to Jira">
                <IconButton
                  aria-label="export"
                  onClick={() => setOpenExport(true)}
                  className={classes.button}
                  size="large">
                  <Jira width={18} height={18} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip id="closeTooltip" title="Close">
              <IconButton aria-label="add" onClick={handleClose} className={classes.button} size="large">
                <SvgIcon component={Close} />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  const style = details.open
    ? { paper: classNames(classes.drawerPaperOpen, classes.drawerInner) }
    : { paper: classNames(classes.drawerPaper, classes.drawerInner) };
  const Component = contentComponent[details.category];
  const key = `${details.category}-${details.element?.id}-${details.element?.epicId}`;

  return (
    <Drawer variant="persistent" className={classes.root} classes={style} anchor="right" open={details.open}>
      <div className={classes.drawerHeader}>{renderHeader({})}</div>
      <Divider />
      <div className={classes.drawerBody}>
        {details.open ? (
          <ErrorBoundary>
            <Component
              ref={child}
              key={key}
              initialValues={details.element}
              element={details.element}
              category={details.category}
              canEdit={canEdit}
              additionalData={details.additionalData}
              {...details.props}
            />
            <DrawerBackdrop open={loading}>
              <CircularProgress color="inherit" />
            </DrawerBackdrop>
          </ErrorBoundary>
        ) : null}
      </div>
      {details.category === 'feature' && !details.element && (
        <ImportDialog handleCancel={cancelImport} openDialog={openImport} title="Import Feature from JIRA" />
      )}
      {details.category === 'feature' && !details.element?.epicId && (
        <ExportDialog
          user={props.user}
          feature={details.element}
          handleCancel={() => setOpenExport(false)}
          openDialog={openExport}
        />
      )}
      <AppBar
        elevation={0}
        position="static"
        className={classes.appBar}
        style={{
          backgroundColor: theme.palette.background.default,
          borderTop: `1px solid ${theme.palette.color.border}`,
        }}>
        <Toolbar>
          {canDelete && (
            <Tooltip id="deleteTooltip" title="Delete">
              <IconButton aria-label="sync" onClick={handleDelete} className={classes.button} size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}

          <div style={{ textAlign: 'right', flex: 1 }}>
            <RoundButton disableElevation buttonText="Cancel" handleClick={handleClose} width="80px" />
            {canEdit && <RoundButton disableElevation buttonText="Save" handleClick={handleSave} width="80px" />}
          </div>
        </Toolbar>
      </AppBar>
    </Drawer>
  );
}
