import { useState } from 'react';
import { Combobox, Pill, PillsInput, InputBase, useCombobox } from '@mantine/core';

const SelectGroupsSearchable = ({ items, label, value, onChange }) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');
  const allItems = items.reduce((acc, group) => [...acc, ...group.keyResults], []);

  const shouldFilterOptions = allItems.every((item) => item.name !== search);
  const filteredGroups = items.map((group) => {
    const filteredOptions = shouldFilterOptions
      ? group.keyResults.filter((item) => item.name.toLowerCase().includes(search.toLowerCase().trim())
        || group.name.toLowerCase().includes(search.toLowerCase().trim()))
      : group.keyResults;

    return { ...group, options: filteredOptions };
  });

  const handleValueSelect = (val: string) =>
    onChange(value.includes(val) ? value.filter((v) => v !== val) : [...value, val]);

  const handleValueRemove = (val: string) =>
    onChange(value.filter((v) => v !== val));

  const totalOptions = filteredGroups.reduce((acc, group) => acc + group.options.length, 0);

  const groups = filteredGroups.map((group) => {
    const options = group.options.map((item) => (
      <Combobox.Option value={item} key={item.name}>
        {item.name}
      </Combobox.Option>
    ));

    return (
      <Combobox.Group label={group.name} key={group.name}>
        {options}
      </Combobox.Group>
    );
  });

  const values = value.map((item) => (
    <Pill key={item.name} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item.name}
    </Pill>
  ));


  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        handleValueSelect(val);
        setSearch('');
      }}
    >
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()} style={{ minHeight: '48px'}}>
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                style={{ minHeight: '23px', padding: '16.5px 0'}}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="OKRs"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {totalOptions > 0 ? groups : <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default SelectGroupsSearchable;
