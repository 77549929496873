import styled from '@emotion/styled';
import { Paper } from '@mantine/core';
import { darken, lighten } from '@mui/system/colorManipulator';

import { color } from '../../../shared/styles/color';
import { styles as IdeaStyles } from '../../Product/IdeaBoard/styles';

export const Card = styled(Paper)(({ theme, ...props }) => ({
  position: 'relative',
  padding: '12px 12px 8px 22px',
  backgroundColor: theme.palette.color.paper,
  cursor: 'pointer',
  border: '0.5px solid ' + theme.palette.color.cardBorder,
  borderBottom: '0px',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '4px',
    top: '4px',
    bottom: '4px',
    left: '4px',
    backgroundColor: props.borderColor,
  },
  ...(props.isOverlay && { cursor: 'grabbing' }),
}));

export const CardBottom = styled(Paper)(({ theme, ...props }) => ({
  position: 'relative',
  padding: '12px 12px 12px 22px',
  backgroundColor: theme.palette.color.paper,
  marginBottom: '8px',
  cursor: 'pointer',
  border: '0.5px solid ' + theme.palette.color.cardBorder,
  ...(props.isOverlay && { cursor: 'grabbing' }),
}));

export const CardContainer = styled(Paper)(({ theme, ...props }) => ({
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? lighten(theme.palette.color.paper, 0.05)
        : darken(theme.palette.color.paper, 0.05),
    '& > *': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? lighten(theme.palette.color.paper, 0.1)
          : darken(theme.palette.color.paper, 0.1),
    },
  },
  ...(props.isOverlay && { cursor: 'grabbing' }),
  boxShadow:
    props.isPortfolioState && !props.isPortfolioPage ? `0px 0px 7px -1px ${color.companyColor} !important` : '',
  marginBottom: '10px',
}));

export const styles = (theme) => ({
  ...IdeaStyles(theme),
  flexColScroll: {
    flex: '1 1 auto',
    marginTop: '10px',
    borderRadius: 4,
    overflowY: 'auto',
    height: '100vh',
  },

  flexSection: {
    height: 'auto',
    flex: 2,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },

  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
});

export const FeatureCard = styled('div')(({ theme }) => ({
  width: 'auto',
  height: '34px',
  borderRadius: '8px',
  padding: '8px',
  border: '1px solid ' + theme.palette.color.tileBorder,
  display: 'flex',
  marginBottom: '8px',
  cursor: 'pointer',
  backgroundColor: theme.palette.mode === 'dark' ? color.card : 'white',
}));
