import React from 'react';
import { ActionIcon, Divider, Grid, Group, Text, Tooltip } from '@mantine/core';
import { ExpandLess, ExpandMore } from '@mui/icons-material'; // Icons for expanding/collapsing
import { useTheme } from '@emotion/react';
import { CenteredCol } from './InitiativeDashboard.style';
import Tag from '../../../components/Common/Tag';
import { openDrawer } from '../../../reactiveVariables'; // Function to open the initiative details drawer
import { uniq } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation hook
import SvgDashboard from '../../../assets/images/Dashboard'; // Correctly referenced dashboard icon component

const InitiativeTitle = ({ onExpand, expanded, initiative, color }) => {
  const theme = useTheme();
  const navigate = useNavigate(); // Hook instantiation for navigation
  const location = useLocation(); // Get the current location
  const features = initiative.features?.nodes || []; // Ensuring features is always an array
  const programmeCount = uniq(features.map((feature) => feature.programmeId)).length; // Calculating the count of unique programmes

  // Enhanced handler for opening the initiative dashboard with additional state
  const handleOpenDashboard = (event) => {
    event.stopPropagation(); // Prevents other handlers from being triggered
    navigate(`/valueStream/initiative/${initiative.id}`, { state: { from: '/valueStream' } }); // Navigate to the initiative dashboard with state (add another 'from')
  };

  const isInitiativeView = location.pathname.includes('/valueStream/initiative/'); // Check if the current location is the initiative view

  return (
    <div style={{ display: 'flex', height: '68px', alignItems: 'center', paddingRight: '8px' }}>
      <Grid gutter={0} w={'100%'} style={{ margin: 0 }}>
        <CenteredCol p={0} span="content" style={{ width: '10px' }}>
          <Divider size="lg" style={{ height: '100%', radius: '5px' }} orientation="vertical" color={color} />
        </CenteredCol>
        <CenteredCol p={0} span="content" style={{ width: '30px', marginTop: '1px' }}>
          {!!initiative.features?.nodes?.length && (
            <ActionIcon color="darkGrey.7" size="xs" variant="filled" onClick={() => onExpand(!expanded)}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </ActionIcon>
          )}
        </CenteredCol>
        <CenteredCol p={0} span="auto" maw="100vh">
          <CenteredCol p={0} span="auto" maw="100vh" align="start" style={{ display: 'flex', height: '25px' }}>
            {!isInitiativeView && !!initiative.features?.nodes?.length && (
              <Tooltip label="Open Initiative View" withArrow transitionProps={{ transition: 'pop', duration: 200 }}>
                <ActionIcon
                  variant="transparent"
                  color="var(--mantine-color-buttonIcon)"
                  onClick={handleOpenDashboard}
                  styles={{ icon: { alignItems: 'flex-start', justifyContent: 'left' } }}>
                  <SvgDashboard />
                </ActionIcon>
              </Tooltip>
            )}
            <Text
              size="sm"
              fw={700}
              lineClamp={1}
              onClick={() => openDrawer(initiative, 'initiative')}
              style={{ '&:hover': { cursor: 'pointer' } }}>
              {initiative.name}
            </Text>
          </CenteredCol>
          <CenteredCol p={0} justify="space-between" style={{ display: 'flex', paddingLeft: '32px' }}>
            <Text size="xs" c={theme.palette.text.secondary}>
              {(initiative.features?.aggregate?.avg?.percentageDone || 0).toFixed()}% Complete
            </Text>
            <Group mr={10} gap={8}>
              <Tag label={`F: ${features.length}`} />
              <Tag label={`P: ${programmeCount}`} />
            </Group>
          </CenteredCol>
        </CenteredCol>
      </Grid>
    </div>
  );
};

export default InitiativeTitle;
