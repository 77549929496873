import React, { memo, useMemo } from 'react';

import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { ResponsiveLine } from '@nivo/line';
import { clamp, findIndex, groupBy, isEmpty, last, sortBy, sum } from 'lodash';
import { DateTime } from 'luxon';

const styleById = {
  Forecast: {
    strokeDasharray: '12, 6',
    strokeWidth: 2,
  },
  default: {
    strokeWidth: 2,
  },
};

const Chip = memo(({ size = 12, color, style = {} }) => (
  <span style={{ display: 'block', width: size, height: size, background: color, ...style }} />
));

const calculateTickValues = (data) => {
  if (!data || data.length === 0) return 'every month';

  const firstDate = DateTime.fromISO(data[0].x);
  const lastDate = DateTime.fromISO(data[data.length - 1].x);

  const diff = lastDate.diff(firstDate, 'months');
  const nbOfMonths = diff.months;

  if (nbOfMonths <= 3) return 'every week';
  if (nbOfMonths <= 6) return 'every 2 weeks';
  if (nbOfMonths <= 12) return 'every months';
  if (nbOfMonths <= 24) return 'every 2 months';
  if (nbOfMonths <= 36) return 'every 3 months';
  return 'every 4 months';
};

const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        })),
      )}
      fill="none"
      stroke={color}
      style={styleById[id] || styleById.default}
    />
  ));
};

const InitiativeBurnup = ({ initiative, sprints, type, cadence }) => {
  const muiTheme = useTheme();

  if (!initiative) return null;

  const features = initiative.features?.nodes || [];
  const metrics = last(initiative.metrics || []);

  const scope =
    type === 'storyPoints' ? initiative.features?.aggregate.sum.storyPointCount || 0 : metrics?.scope.storyCount || 0;

  const totalDone =
    type === 'storyPoints'
      ? sum(features.map((feature) => feature.metrics?.storyPoints?.done || 0))
      : sum(features.map((feature) => feature.metrics?.storyCount?.done || 0));

  const startDate = initiative.startDate || DateTime.now();

  const sprintsByEndDate = Object.entries(groupBy(sortBy(sprints, 'endDate'), 'endDate'));

  const startIndex = findIndex(sprintsByEndDate, ([key, value]) => {
    const diff = DateTime.fromISO(key).diff(startDate, 'weeks');
    return diff.weeks < 2 && diff.weeks >= 0;
  });

  const endIndex =
    (initiative.endDate &&
      findIndex(sprintsByEndDate, ([key, value]) => {
        const diff = DateTime.fromISO(key).diff(initiative.endDate, 'weeks');
        return diff.weeks < 2 && diff.weeks >= 0;
      })) ||
    -1;

  const nowIndex = findIndex(sprintsByEndDate, ([key, value]) => {
    const diff = DateTime.fromISO(key).diff(DateTime.now(), 'weeks');
    return diff.weeks < 2 && diff.weeks >= 0;
  });

  const index = endIndex !== -1 && endIndex < nowIndex ? endIndex : nowIndex;

  const nbCompletedSprints = clamp(index + 1 - startIndex, 0, 1000);

  const fullData = useMemo(
    () =>
      [...Array(nbCompletedSprints).keys()].map((index) => {
        const [date, includedSprints] = sprintsByEndDate[(startIndex < 0 ? 0 : startIndex) + index];

        const totalData = features
          .map((feature) => {
            return includedSprints.reduce((acc, sprint) => {
              const data = feature.storyPointsPerSprint?.[sprint.id];
              if (data) {
                acc.completedStoryPoints = (acc.completedStoryPoints || 0) + (data.completedStoryPoints || 0);
                acc.completedStoryCount = (acc.completedStoryCount || 0) + (data.completedStoryCount || 0);
              }
              return acc;
            }, {});
          })
          .filter((acc) => !isEmpty(acc))
          .reduce((acc, data) => {
            acc.completedStoryPoints = (acc.completedStoryPoints || 0) + (data.completedStoryPoints || 0);
            acc.completedStoryCount = (acc.completedStoryCount || 0) + (data.completedStoryCount || 0);

            return acc;
          }, {});

        return { date: DateTime.fromISO(date) > DateTime.now() ? DateTime.now().toISODate() : date, data: totalData };
      }),
    [initiative, sprints, type],
  );

  const getCadenceInWeeks = (cadence) => {
    switch (cadence) {
      case 'fortnightly':
        return 2;
      case 'monthly':
        return 4;
      case 'weekly':
      default:
        return 1;
    }
  };

  // Calculate the progress line
  let alreadyDone = 0;

  const progressLine = {
    id: 'Progress',
    label: 'Progress',
    color: muiTheme.palette.color.done,
    data: fullData.map((data) => {
      alreadyDone +=
        type === 'storyPoints' ? data.data?.completedStoryPoints || 0 : data.data?.completedStoryCount || 0;
      return { x: data.date, y: Math.floor(alreadyDone) };
    }),
  };

  // Get the date corresponding to the cadence periods
  const threeMonthsAgo = DateTime.now().minus({ months: 3 }).toISODate();

  // Filter fullData for entries within the last 3 months
  const recentData = fullData.filter((entry) => entry.date >= threeMonthsAgo);

  // Calculate the cadence interval (in weeks)
  const cadenceInWeeks = getCadenceInWeeks(cadence);

  // Calculate total done based on cadence periods
  const periodsInCadence = recentData.length / cadenceInWeeks; // Total periods based on cadence (rounded)
  const totalDoneInCadence = recentData.reduce((sum, entry) => {
    const doneValue = Math.floor(Number(entry.data?.completedStoryPoints) || 0);
    return sum + doneValue;
  }, 0);

  // Calculate average velocity per period (rounded down)
  const avgVelocity = periodsInCadence > 0 ? Math.max(Math.floor(totalDoneInCadence / periodsInCadence), 1) : 0;

  // Calculate the number of forecast periods based on avgVelocity and cadence
  const nbOfForecastSprints = avgVelocity ? Math.ceil((scope - alreadyDone) / avgVelocity) : 0;
  const lastDate = fullData[fullData.length - 1]?.date;

  // Reset alreadyDone to the last value of progress (this will continue the forecast from where progress ends)
  const forecastStart = Math.floor(alreadyDone); // Forecast starts from the last progress point, rounded down

  // Initialize forecastData
  let forecastData = [];

  // Get the last progress date
  const lastProgressDate = progressLine.data[progressLine.data.length - 1]?.x; // Get the last date of progress

  // Generate forecastData points
  if (totalDoneInCadence > 0) {
    // If progress was made in the last cadence period
    forecastData = [
      { x: lastProgressDate, y: forecastStart }, // Start from where the progress ended
      ...[...Array(nbOfForecastSprints).keys()].map((index) => {
        const date = DateTime.fromISO(lastProgressDate)
          .plus({ weeks: (index + 1) * cadenceInWeeks }) // Increment by the cadence period
          .toISODate();

        // Increment by avgVelocity, but ensure it does not exceed the scope
        alreadyDone = Math.floor(alreadyDone + avgVelocity); // Round down
        alreadyDone = Math.min(alreadyDone, scope); // Limit alreadyDone to the scope

        return { x: date, y: alreadyDone }; // Return updated forecast point
      }),
    ];
  } else {
    // No progress made in the last cadence period, create flat line for forecast
    for (let i = 0; i < nbOfForecastSprints; i++) {
      const date = DateTime.fromISO(lastProgressDate)
        .plus({ weeks: i * cadenceInWeeks }) // Increment by cadenceInWeeks starting from lastProgressDate
        .toISODate();

      // Keep the same last known value for the forecast
      forecastData.push({ x: date, y: forecastStart });
    }
  }

  // Ensure the forecast line extends to the end date
  if (nbOfForecastSprints > 0) {
    const endDate = DateTime.fromISO(lastProgressDate)
      .plus({ weeks: nbOfForecastSprints * cadenceInWeeks })
      .toISODate();
    forecastData.push({ x: endDate, y: Math.floor(forecastStart) });
  }

  // Build the forecast line
  const forecastLine = {
    id: 'Forecast',
    label: 'Forecast',
    color: muiTheme.palette.color.amber,
    data: forecastData,
  };

  // Scope line calculation
  const extraScopeData =
    nbOfForecastSprints > 0
      ? [...Array(nbOfForecastSprints).keys()].map((index) => {
          const date = DateTime.fromISO(lastDate)
            .plus({ weeks: (index + 1) * cadenceInWeeks }) // Use the cadence interval here
            .toISODate();
          return { x: date, y: Math.floor(scope) }; // Ensure scope is rounded down as well
        })
      : [];

  // Initial scope point
  const initial =
    progressLine.data[0] && initiative.metrics
      ? { x: progressLine.data[0].x, y: initiative.metrics[0].scope?.[type] }
      : undefined;

  // Build the scope line
  const scopeLine = {
    id: 'Scope',
    label: 'Scope',
    color: muiTheme.palette.mode === 'light' ? '#eaeaeb' : alpha('#eaeaeb', 0.5),
    data: [
      initial,
      ...(initiative.metrics || []).map((data) => {
        return { x: data.date, y: data.scope?.[type] || 0 };
      }),
      ...extraScopeData, // Include the expanded scope data based on the cadence
    ].filter(Boolean),
  };

  const commonProps = {
    margin: { top: 50, right: 40, bottom: 50, left: 70 },
    animate: true,
    enableSlices: 'x',
  };

  const theme = {
    axis: {
      legend: {
        text: {
          fontSize: 14,
          fill: '#93a0b5',
        },
      },
      ticks: {
        text: {
          fontSize: 11,
          fill: '#93a0b5',
        },
      },
      domain: {
        line: {
          stroke: '#eaeaeb',
          strokeWidth: 2,
        },
      },
    },
    tooltip: {
      container: {
        background: muiTheme.palette.background.default,
      },
    },
  };

  const tickValues = calculateTickValues(scopeLine.data);

  return (
    <ResponsiveLine
      {...commonProps}
      theme={theme}
      data={[progressLine, forecastLine, scopeLine]}
      enableArea={true}
      enablePoints={false}
      areaOpacity={0.5}
      colors={{ datum: 'color' }}
      pointSize={10}
      pointBorderWidth={2}
      useMesh={true}
      isInteractive={true}
      enableCrosshair={true}
      curve="monotoneX"
      xFormat="time:%d/%m/%Y"
      xScale={{
        format: '%Y-%m-%d',
        precision: 'day',
        type: 'time',
        useUTC: false,
      }}
      yScale={{
        type: 'linear',
      }}
      pointBorderColor="white"
      layers={['grid', 'markers', 'axes', 'areas', 'crosshair', DashedSolidLine, 'points', 'slices', 'mesh', 'legends']}
      axisLeft={{
        tickSize: 0,
        tickPadding: 12,
        tickValues: 4,
        legend: type === 'storyPoints' ? 'Story Points' : 'Story Count',
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      axisBottom={{
        format: tickValues.includes('week') ? '%d %b %Y' : '%b %Y',
        legendOffset: -12,
        tickValues: calculateTickValues(scopeLine.data),
      }}
      sliceTooltip={({ slice }) => (
        <div
          style={{
            background: muiTheme.palette.color.background,
            color: 'inherit',
            fontSize: 'inherit',
            borderRadius: '2px',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
            padding: '5px 9px',
          }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>
              <strong>{slice.points?.[0].data.xFormatted}</strong>
            </span>
          </div>
          {slice.points.map((point) => (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                <Chip color={point.color} style={{ marginRight: '10px' }} />
                <span style={{ marginRight: '10px' }}>{point.serieId}</span>
              </div>
              <span>
                <strong>{point.data.y}</strong>
              </span>
            </div>
          ))}
        </div>
      )}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: -100,
          translateY: -50,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

export default InitiativeBurnup;
