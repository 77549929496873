import React from 'react';

import { ActionIcon, Tooltip } from '@mantine/core';
import { DateTime } from 'luxon';

import SvgDashboard from '../../../assets/images/Dashboard';
import { date } from '../../../utils/helpers';
import LinearWithValueLabel from '../components/OKRTable/LinearWithValueLabel';
import { Display2 } from '../InitiativesDashboard/InitiativeDashboard.style';
import { getInitiativeBgColor, getMantineColor } from '../utils';

function createData(item, openDashboard, theme) {
  const { id, rank, name, plannedRealisationDate, endDate, features, ragStatus, isDone, initiative_state } = item;
  const progress = features?.aggregate?.avg?.percentageDone || 0;
  const daysOverrun =
    plannedRealisationDate && endDate ? endDate.diff(date(plannedRealisationDate), 'days').toObject().days : 0;

  return {
    id,
    rank,
    name,
    featuresIn: <span data-value={features?.aggregate?.count}>{`${features?.aggregate?.count} Features`}</span>,
    progress: <LinearWithValueLabel data-value={progress} value={progress.toFixed()} />,
    plannedRealisationDate:
      plannedRealisationDate && DateTime.fromISO(plannedRealisationDate).toLocaleString(DateTime.DATE_SHORT),
    estimatedCompletionDate: endDate && endDate.toLocaleString(DateTime.DATE_SHORT),
    expand: features?.aggregate?.count > 0,
    ragStatus: (
      <Tooltip
        withArrow
        opened={plannedRealisationDate ? null : false}
        color={getMantineColor(item)}
        transitionProps={{ transition: 'pop', duration: 200 }}
        label={`${daysOverrun > 0 ? 'Overrun' : 'Early'} By: ${Math.abs(daysOverrun)} days`}>
        <div>
          <Display2
            data-value={ragStatus}
            color={getInitiativeBgColor(item, theme)}
            bgColor={getInitiativeBgColor(item, theme)}
            variant="light"
            style={{ width: '65px' }}>
            {isDone ? 'DONE' : ragStatus.toUpperCase()}
          </Display2>
        </div>
      </Tooltip>
    ),
    action: features?.aggregate?.count ? (
      <Tooltip withArrow transitionProps={{ transition: 'pop', duration: 200 }} label="Open Initiative View">
        <ActionIcon
          ml={4}
          variant="transparent"
          color="var(--mantine-color-buttonIcon)"
          onClick={(event) => openDashboard(event, item)}>
          <SvgDashboard />
        </ActionIcon>
      </Tooltip>
    ) : null,
    initiative_state,
  };
}

export function getRowsData(data, openDashboard, theme) {
  let rows = [];
  if (Array.isArray(data) && data.length) {
    rows = data.map((item) => createData(item, openDashboard, theme));
  }
  return rows;
}
