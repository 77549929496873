import React, { useState, useCallback, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Text, Tooltip, ActionIcon, SegmentedControl, useMantineColorScheme } from '@mantine/core';
import { ExpandMore } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { uniq, camelCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { DialogFeaturePopup } from './DialogFeaturePopup';
import { DialogPopup } from './DialogPopup';
import { FeatureCard } from './styles';
import SvgDashboard from '../../../assets/images/Dashboard';
import { styles } from '../../../components/Dashboard/FeatureBoard/styles';
import { GET_TEAMS } from '../../../containers/Settings/GraphQL/teams';
import { openDrawer, stickyHeaderVar } from '../../../reactiveVariables';
import { lightColors } from '../../../shared/styles/color';
import { STAGE } from '../../../utils/formConstants';
import LinearWithValueLabel from '../../ValueStream/components/OKRTable/LinearWithValueLabel';
import { getInitiativeBgColor } from '../../ValueStream/utils';

const useStyles = makeStyles(styles);

const Timelines = ({ initiatives }) => {
  const theme = useTheme();
  const stickyHeader = useReactiveVar(stickyHeaderVar);

  const classes = useStyles({ stickyHeader: stickyHeader });
  const navigate = useNavigate();
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  const [showAllFeatures, setShowAllFeatures] = useState({});
  const [hoveredInitiative, setHoveredInitiative] = useState(null);
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [initiativesToggle, setInitiativesToggle] = useState('allInitiatives');

  const { colorScheme } = useMantineColorScheme();

  useEffect(() => () => stickyHeaderVar(false), []);

  const excludeDoneInitiatives = initiatives.filter((initiative) => initiative.initiative_state !== 'Done');

  const handleInitiativeClick = (initiative) => {
    openDrawer({ ...initiative }, 'initiative');
  };

  const handleFeatureClick = (feature) => {
    openDrawer({ ...feature }, 'feature');
  };

  const handleNavigate = useCallback(
    (featureId, event) => {
      event.stopPropagation();
      navigate(`/valueStream/initiative/${featureId}`, {
        state: { from: '/valueStream/kanban', tab: 'breakdown' },
      });
    },
    [navigate],
  );

  const showAllFeaturesForStage = (initiativeId, stageValue) => {
    setShowAllFeatures((prev) => ({
      ...prev,
      [initiativeId]: {
        ...prev[initiativeId],
        [stageValue]: true,
      },
    }));
  };

  const featureRag = (feature) => {
    if (feature.status === 'Done') {
      return theme.palette.color.done;
    }
    if (feature.ragStatus === 'Green') {
      return theme.palette.color.green;
    }
    if (feature.ragStatus === 'Red') {
      return theme.palette.color.red;
    }
    if (feature.ragStatus === 'Amber') {
      return theme.palette.color.amber;
    }
  };

  const renderInitiativesDetails = (initiative) => {
    const plannedInitiative = Object.values(STAGE).map((stageValue) => {
      const featuresForStage = initiative.features.nodes.filter((feature) => feature.stage === camelCase(stageValue));

      const displayedFeatures = showAllFeatures[initiative.id]?.[stageValue]
        ? featuresForStage
        : featuresForStage.slice(0, 3);

      return (
        <TableCell
          key={stageValue}
          style={{
            minWidth: '220px',
            maxWidth: '220px',
            verticalAlign: 'top',
            borderRight: `1px solid ${theme.palette.color.paleGrey2}`,
            backgroundColor: theme.palette.mode === 'dark' ? 'black' : lightColors.background,
          }}>
          {displayedFeatures.map((feature) => (
            <>
              <FeatureCard
                key={feature.id}
                onClick={() => handleFeatureClick(feature)}
                onMouseEnter={() => setHoveredFeature(feature.id)}
                onMouseLeave={() => setHoveredFeature(null)}
                style={{ padding: '8px 0' }}>
                <div
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    paddingLeft: '8px',
                    borderLeft: `4px solid ${featureRag(feature)}`,
                    cursor: 'pointer',
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                  }}>
                  {feature.name.length > 18 ? `${feature.name.slice(0, 19)}...` : feature.name}
                </div>
              </FeatureCard>
              {feature && hoveredFeature === feature.id && (
                <DialogFeaturePopup feature={feature} setIsFeatureHovered={setHoveredFeature} teams={teams} />
              )}
            </>
          ))}
          {!showAllFeatures[initiative.id]?.[stageValue] && featuresForStage.length > 3 && (
            <FeatureCard
              onClick={() => showAllFeaturesForStage(initiative.id, stageValue)}
              style={{
                backgroundColor: '#EFEFEF',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '0px',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
              }}>
              <div
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: '12px',
                  alignSelf: 'center',
                }}>
                Show all features <ExpandMore style={{ alignSelf: 'center' }} />
              </div>
            </FeatureCard>
          )}
        </TableCell>
      );
    });

    const borderColor = getInitiativeBgColor(initiative, theme);
    const progress = initiative.features?.aggregate?.avg?.percentageDone || 0;
    const programmeCount = uniq(initiative.features.nodes.map((feature) => feature.programmeId)).length;

    return (
      <TableRow key={initiative.id} className={classes.row} style={{ height: 'auto', backgroundColor: '#F7F7F8' }}>
        <TableCell
          className={classes.tableHeadCol}
          onClick={() => handleInitiativeClick(initiative)}
          style={{
            minWidth: '220px',
            maxWidth: '220px',
            borderLeft: `5px solid ${borderColor}`,
            height: 'auto',
            borderRight: `1px solid  ${theme.palette.color.paleGrey2}`,
            borderBottom: `1px solid  ${theme.palette.color.paleGrey2}`,
            position: 'relative',
          }}>
          <div
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setHoveredInitiative(initiative.id)}
            onMouseLeave={() => setHoveredInitiative(null)}>
            {initiative.features?.aggregate?.count > 0 && (
              <Tooltip color="dark" label="Open Initiative view" zIndex={1000}>
                <ActionIcon
                  variant="transparent"
                  color="var(--mantine-color-buttonIcon)"
                  onClick={(event) => handleNavigate(initiative.id, event)}
                  style={{ position: 'relative', top: '3px' }}
                  ml={-4}>
                  <SvgDashboard />
                </ActionIcon>
              </Tooltip>
            )}
            <span className={classes.teamName} style={{ marginLeft: '0px' }}>
              {initiative.name}
            </span>

            {initiative.owner && (
              <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '10px' }}>
                <Text size="sm" c={theme.palette.text.secondary}>
                  Owner: {initiative.owner}
                </Text>
              </div>
            )}

            {initiative.rank && (
              <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '10px' }}>
                <Text size="sm" c={theme.palette.text.secondary}>
                  Rank: {initiative.rank}
                </Text>
              </div>
            )}

            <Text size="sm" c={theme.palette.text.secondary} style={{ marginTop: '10px' }}>
              Programmes: {programmeCount}
            </Text>
            <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '10px' }}>
              <Text size="sm" c={theme.palette.text.secondary}>
                Features: {initiative.features.nodes.length}
              </Text>
            </div>
            <div style={{ minWidth: '100%', marginTop: '10px' }}>
              <LinearWithValueLabel data-value={progress} value={progress.toFixed()} minWidth={0} />
            </div>
          </div>
        </TableCell>
        {plannedInitiative}
        {hoveredInitiative === initiative.id && <DialogPopup initiative={initiative} />}
      </TableRow>
    );
  };
  const onLeave = ({ currentPosition, previousPosition }) => {
    const isAbove = currentPosition === Waypoint.above;
    const wasInside = previousPosition === Waypoint.inside;

    if (isAbove && wasInside) {
      stickyHeaderVar(true);
    }
  };

  const onEnter = () => {
    stickyHeaderVar(false);
  };

  const handleSwitch = (value) => {
    setInitiativesToggle(value);
  };

  const TOGGLE_DONE = [
    { value: 'allInitiatives', label: 'All Initiatives' },
    { value: 'excludeDone', label: 'Exclude Done' },
  ];

  return (
    <>
      <SegmentedControl
        styles={{
          root: { backgroundColor: colorScheme === 'dark' ? '#484851' : '#FFFFFF' },
          label: { color: colorScheme === 'dark' ? '#ffffff' : '#db36c0' },
        }}
        radius="xl"
        value={initiativesToggle}
        color={colorScheme === 'dark' ? 'darkGrey.0' : 'bigagilePink.0'}
        onChange={handleSwitch}
        data={TOGGLE_DONE}
        mb={10}
      />
      <Table className={classes.table} style={{ tableLayout: 'inherit' }} stickyHeader={stickyHeader}>
        <TableHead>
          <Waypoint onLeave={onLeave} onEnter={onEnter} />
          <TableRow>
            <TableCell
              key="header"
              className={classes.tableHeadRow}
              style={{ minWidth: '280px', maxWidth: '280px', height: 'auto' }}>
              Initiatives
            </TableCell>
            {Object.values(STAGE).map((columnName) => (
              <TableCell
                key={columnName}
                className={classes.tableHeadRow}
                style={{
                  minWidth: '220px',
                  maxWidth: '220px',
                  height: 'auto',
                  borderBottom: '1px solid  #dfe2e8',
                }}>
                {columnName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {initiativesToggle === 'allInitiatives'
            ? initiatives.map((initiative) => renderInitiativesDetails(initiative))
            : excludeDoneInitiatives.map((initiative) => renderInitiativesDetails(initiative))}
        </TableBody>
      </Table>
    </>
  );
};

export default Timelines;
