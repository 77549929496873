import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import KanbanColumn from './kanbanColumn';
import KanbanItem from './kanbanItem';
import { styles } from './styles';
import { canScroll } from '../../../containers/Delivery/utils';
import { selectedProductThemeVar } from '../../../reactiveVariables';
import { GET_INITIATIVE_STATE_CONFIG } from '../../Settings/GraphQL/configuration';
import { UPDATE_INITIATIVE_MANY } from '../../ValueStream/Initiatives/graphql';

const useStyles = makeStyles(styles);

export default function KanbanTab({ refetchListOfInitiatives, initiatives }) {
  const { data: { initiativeStates = [] } = {} } = useQuery(GET_INITIATIVE_STATE_CONFIG);

  const [updateInitiativeMany] = useMutation(UPDATE_INITIATIVE_MANY);
  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const classes = useStyles();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { distance: 0 },
  });
  const sensors = useSensors(mouseSensor);

  const [sortColumnBy, setSortColumnBy] = useState({});
  const [activeId, setActiveId] = useState(null);
  const [columns, setColumns] = useState([]);

  const valueStreamInitiativeStates = useMemo(() => {
    return initiativeStates
      .filter((state) => state?.value_stream === initiatives[0]?.valueStream)
      .filter((state) => state.inUse === true);
  }, [initiativeStates, initiatives]);

  const groupByInitiativeState = useCallback((initiatives, valueStreamInitiativeStates) => {
    const columns = valueStreamInitiativeStates
      .map((state) => ({
        title: state.name,
        value: state.name,
        data: [],
        rank: state.rank,
      }))
      .sort((a, b) => a.rank - b.rank);

    const columnsMap = columns.reduce((acc, col) => {
      acc[col.value] = col;
      return acc;
    }, {});

    initiatives.forEach((initiative) => {
      const title = initiative.initiative_state || 'Unknown state';
      if (!columnsMap[title]) {
        columnsMap[title] = {
          title,
          value: title,
          data: [],
        };
        columns.push(columnsMap[title]);
      }
      columnsMap[title].data.push(initiative);
    });

    return columns;
  }, []);

  const moveUnknownStateToFront = useCallback((columns) => {
    const unknownStateIndex = columns.findIndex((col) => col.title === 'Unknown state');
    if (unknownStateIndex !== -1) {
      const unknownStateObject = columns.splice(unknownStateIndex, 1)[0];
      columns.unshift(unknownStateObject);
    }
    return columns;
  }, []);

  const filterColumnsByField = useCallback((columns, filterId, field) => {
    if (filterId === null || filterId?.length === 0 || filterId === 'all' || filterId === 'x') {
      return columns;
    }

    return columns.filter((column) => column[field].some((theme) => theme.id === filterId));
  }, []);

  const sortColumns = useCallback(
    (columns) => {
      return columns.map((column) => {
        const sortCriteria = sortColumnBy[column.value]?.sortBy;
        return {
          ...column,
          data: [...column.data].sort((a, b) => {
            if (sortCriteria === 'name') {
              return a.name.localeCompare(b.name);
            }
            return a.rank - b.rank;
          }),
        };
      });
    },
    [sortColumnBy],
  );

  useEffect(() => {
    let filteredColumns = filterColumnsByField(initiatives, selectedProductTheme, 'productThemes');

    filteredColumns = groupByInitiativeState(filteredColumns, valueStreamInitiativeStates);
    filteredColumns = moveUnknownStateToFront(filteredColumns);
    filteredColumns = sortColumns(filteredColumns);

    setColumns(filteredColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductTheme, valueStreamInitiativeStates]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;
    const activeFromColumn = active.data.current.fromColumn;
    const fromColumnIndex = columns.findIndex((column) => column.value === activeFromColumn);
    const overColumn = over.data.current.column;

    if (overColumn && activeFromColumn !== overColumn.value) {
      handleMoveToDifferentColumn(overColumn, fromColumnIndex, activeId, overId);
    } else {
      return;
    }
  };

  const handleMoveToDifferentColumn = (overColumn, fromColumnIndex, activeId, overId) => {
    const toColumnIndex = columns.findIndex((column) => column.value === overColumn.value);
    const fromColumn = columns[fromColumnIndex];
    const toColumn = columns[toColumnIndex];
    const movingItem = fromColumn.data.find((feature) => feature.id === activeId);

    const updatedFromColumn = {
      ...fromColumn,
      data: fromColumn.data.filter((feature) => feature.id !== activeId),
    };

    const updatedToColumn = {
      ...toColumn,
      data: [...toColumn.data, movingItem].sort((a, b) => {
        return sortColumnBy[toColumn.value]?.sortBy === 'name' ? a.name.localeCompare(b.name) : a.rank - b.rank;
      }),
    };

    const newItems = [...columns];
    newItems[fromColumnIndex] = updatedFromColumn;
    newItems[toColumnIndex] = updatedToColumn;

    const updates = [
      {
        where: { id: { _eq: activeId } },
        _set: { initiative_state: overId },
      },
    ];

    updateInitiativeMany({
      variables: { updates },
    }).then(() => {
      refetchListOfInitiatives();
    });

    setColumns(newItems);
  };

  const sortByOption = (columnName, sortBy) => {
    const sortedList = columns.map((item) => {
      if (item.title === columnName) {
        item.data.sort((a, b) => {
          if (sortBy === 'rank') {
            return a[sortBy] - b[sortBy];
          } else if (sortBy === 'name') {
            return a.name.localeCompare(b.name);
          }
          return 0;
        });

        return {
          ...item,
          sortedBy: sortBy,
        };
      }

      return item;
    });

    setSortColumnBy({
      ...sortColumnBy,
      [columnName]: {
        sortBy,
      },
    });

    setColumns(sortedList);
  };

  const handleDragStart = (event) => {
    setActiveId(event.active?.data?.current);
  };

  const renderDragOverlay = (data) => (
    <KanbanItem
      isOverlay={true}
      key="overlay"
      column={data.column}
      feature={data.feature}
      initiativeLength={initiatives.length}
      showRank={true}
    />
  );

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          measuring={measuringConfig}
          autoScroll={{ canScroll }}
          collisionDetection={closestCenter}>
          <div
            style={{
              overflowX: 'auto',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              flexWrap: 'nowrap',
            }}>
            <Grid
              key="container"
              container
              className={classes.flexSection}
              spacing={0}
              style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
              {columns.map((column) => (
                <KanbanColumn
                  classes={classes}
                  key={column.value}
                  title={column.title}
                  column={column}
                  onSortChange={(columnName, selectedOption) => sortByOption(columnName, selectedOption)}
                  initiativeLength={initiatives.length}
                />
              ))}
            </Grid>
          </div>
          <DragOverlay>{activeId ? renderDragOverlay(activeId) : null}</DragOverlay>
        </DndContext>
      </div>
    </div>
  );
}
