import GroupsIcon from '@mui/icons-material/Groups';

import SvgDashboard from '../../assets/images/Dashboard';
import SvgDetailedView from '../../assets/images/DetailedView';
import SvgFeatures from '../../assets/images/Features';
import SvgIdeation from '../../assets/images/Ideation';
import SvgInitiatives from '../../assets/images/Initiatives';
import SvgMilestones from '../../assets/images/Milestones';
import SvgObjectives from '../../assets/images/Objectives';
import SvgOKRs from '../../assets/images/OKRs';
import SvgOperationalPortfolio from '../../assets/images/OperationalPortfolio';
import SvgPrioritisation from '../../assets/images/Prioritisation';
import SvgProgramme from '../../assets/images/Programme';
import SvgRisks from '../../assets/images/Risks';
import SvgRoadmap from '../../assets/images/Roadmap';
import SvgSettings from '../../assets/images/Settings';
import SvgShowDependencies from '../../assets/images/ShowDependencies';
import SvgSteps from '../../assets/images/Steps';
import SvgSummaryView from '../../assets/images/SummaryView';
import SvgThinkTank from '../../assets/images/ThinkTank';
import SvgValueStream from '../../assets/images/ValueStream';

export const MENU_ITEMS = [
  {
    name: 'Product',
    uri: '/product',
    subMenus: [
      // { name: 'Dashboard', uri: '/product', icon: SvgDashboard },
      { name: 'Product Kanban', uri: '/product/ideation', icon: SvgIdeation },
      { name: 'Roadmap', uri: '/product/roadmap', icon: SvgRoadmap },
      { name: 'Prioritisation', uri: '/product/prioritisation', icon: SvgPrioritisation },
      { name: 'Features', uri: '/product/features', icon: SvgFeatures },
    ],
  },
  // {
  //   name: 'Planner',
  //   uri: '/planning',
  //   disabled: true,
  //   subMenus: [
  //     { name: 'Program Board', uri: '/planning/program', icon: SvgDashboard, soon: true },
  //     { name: 'Team Board', uri: '/planning/team', icon: SvgDashboard, soon: true },
  //   ],
  // },
  {
    name: 'Delivery',
    uri: '/delivery',
    subMenus: [
      { name: 'Dashboard', uri: '/', icon: SvgDashboard },
      { name: 'Summary View', uri: '/delivery/summary', icon: SvgSummaryView },
      { name: 'Detailed View', uri: '/delivery/detailed', icon: SvgDetailedView },
      { name: 'Dependencies', uri: '/delivery/dependencies', icon: SvgShowDependencies },
      { name: 'Objectives', uri: '/delivery/objectives', icon: SvgObjectives },
      { name: 'Risks', uri: '/delivery/risks', icon: SvgRisks },
    ],
  },
  {
    name: 'Value Stream',
    uri: '/valueStream',
    subMenus: [
      { name: 'Dashboard', uri: '/valueStream', icon: SvgDashboard },
      // { name: 'Flow Metrics', uri: '/valueStream/flow', icon: SvgDashboard, soon: true  },
      // { name: 'Steps', uri: '/valueStream/steps', icon: SvgSteps, soon: true },
      { name: 'Kanban', uri: '/valueStream/kanban', icon: SvgIdeation },
      { name: 'Initiatives', uri: '/valueStream/initiatives', icon: SvgInitiatives },
      { name: 'Milestones', uri: '/valueStream/milestones', icon: SvgMilestones },
      { name: 'Product Themes', uri: '/valueStream/productThemes', icon: SvgFeatures },
    ],
  },
  {
    name: 'Organisation',
    uri: '/organisation',
    subMenus: [
      { name: 'Dashboard', uri: '/organisation/dashboard', icon: SvgDashboard },
      { name: 'OKRs', uri: '/organisation/okrs', icon: SvgOKRs },
      { name: 'Operational Portfolio', uri: '/organisation/portfolio', icon: SvgOperationalPortfolio },
      { name: 'Portfolio Initiatives', uri: '/organisation/initiatives', icon: SvgInitiatives },
      { name: 'Think Tank', uri: '/organisation/thinkTank', icon: SvgThinkTank },
    ],
  },
  {
    name: 'Admin',
    uri: '/admin',
    subMenus: [
      { name: 'Integrations', uri: '/admin/integration', icon: SvgDashboard },
      { name: 'Increments', uri: '/admin/increments', icon: SvgSteps },
      { name: 'Value Streams', uri: '/admin/valueStreams', needAdmin: true, icon: SvgValueStream },
      { name: 'Programmes', uri: '/admin/programmes', needAdmin: true, icon: SvgProgramme },
      { name: 'Teams', uri: '/admin/teams', needAdmin: true, icon: GroupsIcon },
      { name: 'Projects / Products', uri: '/admin/projects', needAdmin: true, icon: SvgInitiatives },
      { name: 'Users', uri: '/admin/users', needAdmin: true, icon: SvgOKRs },
      { name: 'Configuration', uri: '/admin/configuration', needAdmin: true, icon: SvgSettings },
    ],
  },
];
