import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { useQuery } from '@apollo/client';

import { headCells } from './constants';
import { getRowsData } from './helper';
import EntityTable from '../../../components/Common/EntityTable';
import { openDrawer } from '../../../reactiveVariables';
import { exportCSVData, getFieldValue } from '../../../utils/export';
import { GET_PROGRAMMES } from '../GraphQL/programmes';
import { GET_TEAMS } from '../GraphQL/teams';

const Programmes = (props, ref) => {
  const { searchQuery } = props;
  const searchQueryLowerCase = searchQuery.toLowerCase().trim();

  const { data: { programmes = [] } = {} } = useQuery(GET_PROGRAMMES);
  const { data: { teams = [] } = {} } = useQuery(GET_TEAMS);

  const activeProgrammes = programmes.filter((programme) => programme.valueStream.isActive === true);

  useImperativeHandle(ref, () => ({
    export: () => exportProgrammes(),
  }));

  const exportProgrammes = useCallback(() => {
    const data = activeProgrammes.map((rowData) => headCells.map((columnDef) => getFieldValue(rowData, columnDef)));

    exportCSVData(headCells, data, 'Value Streams');
  }, [activeProgrammes]);

  const onRowClick = (event, row) => {
    const programme = activeProgrammes.find((programme) => programme?.id === row.id);
    const programmeTeams = teams.filter((team) => team.programmes?.includes(row.id));

    openDrawer(programme, 'programme', programmeTeams);
  };

  return (
    <EntityTable
      headCells={headCells}
      rows={getRowsData(activeProgrammes, teams)}
      searchQuery={searchQueryLowerCase}
      expand={false}
      handleRowClick={onRowClick}
    />
  );
};

export default forwardRef(Programmes);
