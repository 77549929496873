import React from 'react';

import { Tooltip } from '@mantine/core';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Download from '@mui/icons-material/GetApp';
import { IconButton, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Search from 'components/Common/BigAgileTable/TableToolBar/Search';
import ButtonIcon from 'components/Common/Button/ButtonIcon';
import PropTypes from 'prop-types';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const TableToolBar = (props) => {
  const {
    onHandleAdd,
    handleDownloadClick,
    handleSearch,
    title,
    disableAddButton,
    tooltipMessage,
    hideToolBar = false,
  } = props;
  const classes = useStyles(props);

  const getAddButton = () => {
    return (
      <IconButton
        key="add"
        aria-label="Add"
        color="inherit"
        disabled={disableAddButton}
        onClick={onHandleAdd}
        size="small"
        style={{ marginLeft: '6px' }}>
        <AddBoxIcon />
      </IconButton>
    );
  };

  return (
    <Toolbar className={classes.appBar} disableGutters>
      <div className={classes.toolbarLeft}>
        <span style={{ fontWeight: 600, fontSize: '20px' }}>{title}</span>
        {tooltipMessage ? (
          <Tooltip withArrow transition="scale" position="top" transitionDuration={200} label={tooltipMessage}>
            <div>{getAddButton()}</div>
          </Tooltip>
        ) : (
          getAddButton()
        )}
      </div>
      {!hideToolBar && (
        <div className={classes.toolbarRight}>
          <Search handleChange={handleSearch} />
          <ButtonIcon icon={Download} handleClick={handleDownloadClick} />
        </div>
      )}
    </Toolbar>
  );
};

TableToolBar.propTypes = {
  onHandleAdd: PropTypes.func.isRequired,
  handleDownloadClick: PropTypes.func.isRequired,
  handleSearch: PropTypes.func,
  isRisksTab: PropTypes.bool,
};

export default TableToolBar;
